import React, { PureComponent } from 'react'
import Helmet from 'react-helmet'
import styled from 'styled-components'
import { hsl } from 'polished'
import { isAndroid, isIOS } from 'react-device-detect'

import Layout from '../components/Layout'
import Section from '../components/Section'
import ContactSection from '../components/ContactSection'
import TextSection from '../components/TextSection'
import Modal from '../components/Modal'
import Button from '../components/Button'
import Coupon from '../components/Coupon'
import { ProductGrid, Product } from '../components/Product'
import { withAppContext } from '../contexts/AppContext'

import iPadSchorndorfer from '../images/ipads/Schorndorfer_Zeitung.png'
import iPadWaiblinger from '../images/ipads/Waiblinger_Kreiszeitung.png'
import iPadWelzheimer from '../images/ipads/Welzheimer_Zeitung.png'
import iPadWinnender from '../images/ipads/Winnender_Zeitung.png'

import checkmark from '../images/icons/checkmark.svg'

const newspapers = {
  winnenden: 'Winnender Zeitung',
  welzheim: 'Welzheimer Zeitung',
  waiblingen: 'Waiblinger Kreiszeitung',
  schorndorf: 'Schorndorfer Nachrichten',
}

const ModalWrapper = styled.div`
  display: flex;
`
const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  align-items: center;
`

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  @media (max-width: 945px) {
    flex-direction: column;
  }

  > button {
    margin: 0 10px;

    @media (max-width: 945px) {
      margin: 10px;
    }

    span {
      &:before {
        display: none;
      }
    }

    &.negate {
      background-color: #fff;
      border: 1px solid ${hsl(198, 1, 0.45)};
      color: ${hsl(198, 1, 0.45)};

      &:hover {
        background-color: rgba(0, 0, 0, 0.1);
      }
    }
  }
`

const TryWrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;

  strong {
    font-size: 20px;
    margin-bottom: 20px;
  }

  a {
    margin-top: 25px;
    margin-bottom: 25px;
    font-size: 20px;
    color: #00a0e2;
    transition: all 200ms linear;

    &:hover {
      color: #08222d;
    }
  }
`

class OffersPage extends PureComponent {
  state = {
    showTryModal: false,
  }

  toggleTryModal = () => {
    this.setState(prevState => ({ showTryModal: !prevState.showTryModal }))
  }

  render() {
    const { showTryModal } = this.state
    const { appContext } = this.props

    return (
      <>
        <Helmet>
          <title>Aboangebote</title>
        </Helmet>

        <Layout>
          <Section variant="blue">
            <h1>
              {appContext.edition === 'zvw' ? (
                <>
                  Die <strong>digitale Ausgabe</strong> Ihrer Tageszeitung
                </>
              ) : (
                <>
                  Die <strong>digitale Ausgabe</strong> der
                  <br /> {newspapers[appContext.edition]}
                </>
              )}
            </h1>
          </Section>

          <TextSection
            image={
              appContext.edition === 'winnenden'
                ? iPadWinnender
                : appContext.edition === 'welzheim'
                ? iPadWelzheimer
                : appContext.edition === 'schorndorf'
                ? iPadSchorndorfer
                : appContext.edition === 'zvw'
                ? iPadWaiblinger
                : iPadWaiblinger
            }
            content={
              <>
                <strong>
                  Die wichtigste Informationsquelle im Rems-Murr-Kreis
                </strong>

                <p>
                  Mit der neuen digitalen Ausgabe lesen Sie überall und wann
                  immer Sie möchten Ihre Zeitung mit unseren vier
                  Lokal-Ausgaben: Waiblingen, Schorndorf, Welzheim und
                  Winnenden. Sie können dabei auf das kompakte Format von
                  Smartphone, Tablet oder Notebook zurückgreifen.
                </p>

                <p>
                  Täglich mit topaktueller Abendausgabe. Schon am Abend, um
                  18.30 Uhr, können Sie die Nachrichten vom Tag online abrufen.
                </p>
              </>
            }
          />

          <ProductGrid>
            <Product
              highlightedOffer={true}
              title="Starter-Angebot"
              subtitle="14 Tage kostenlos testen"
              description="Testen Sie die digitale Zeitung unverbindlich für 14 Tage. Endet automatisch!"
              features={
                <>
                  <span>
                    <img src={checkmark} alt="" />
                    Keine Eingabe von Daten notwendig
                  </span>
                  <span>
                    <img src={checkmark} alt="" />
                    Unverbindlich und kostenlos
                  </span>
                </>
              }
              image="https://zvw-shop.de/media/image/08/6c/c0/tablet-mit-hand-freigestellt592fb8576a887.jpg"
              onButtonClick={this.toggleTryModal}
            />
            {/* <Product
              title="Das freie Abo"
              subtitle="monatlich kündbar"
              description="Die digitale Ausgabe Ihrer Tageszeitung vom Zeitungsverlag Waiblingen. 1. Monat gratis"
              oldPrice={appContext.isPrintSubscriber ? 25.4 : ''}
              currentPrice={appContext.isPrintSubscriber ? 6 : 25.4}
              image="https://zvw-shop.de/media/image/08/6c/c0/tablet-mit-hand-freigestellt592fb8576a887.jpg"
              zvwShopLink={
                appContext.isPrintSubscriber
                  ? 'https://zvw-shop.de/app-angebote/das-freie-abo-printleser?utm_source=ext&utm_medium=link&utm_campaign=app-angebote'
                  : 'https://zvw-shop.de/app-angebote/das-freie-abo-digi?utm_source=ext&utm_medium=link&utm_campaign=app-angebote'
              }
            />
            <Product
              title="Digital-Paket"
              subtitle="Samsung Galaxy Tab A (7 Zoll)"
              description="Das besonders kleine und günstige Tablet passt in jede Handtasche."
              oldPrice={appContext.isPrintSubscriber ? 25.4 : ''}
              currentPrice={appContext.isPrintSubscriber ? 6 : 25.4}
              additionalPayment={19}
              image="https://zvw-shop.de/media/image/0c/e8/3b/samsung7oll_shop592ff223a74bc.jpg"
              zvwShopLink={
                appContext.isPrintSubscriber
                  ? 'https://zvw-shop.de/app-angebote/samsung-tab-7-zoll-print?utm_source=ext&utm_medium=link&utm_campaign=app-angebote'
                  : 'https://zvw-shop.de/app-angebote/samsung-tab-7-zoll-digi?utm_source=ext&utm_medium=link&utm_campaign=app-angebote'
              }
            />
            <Product
              title="Digital-Paket"
              subtitle="Samsung Galaxy Tab A (10.5 Zoll)"
              description="Samsung-Tablet mit Android-Betriebssystem"
              oldPrice={appContext.isPrintSubscriber ? 35.4 : ''}
              currentPrice={appContext.isPrintSubscriber ? 16 : 35.4}
              additionalPayment={1}
              image="https://zvw-shop.de/media/image/db/1e/c3/A202-Samsung_S2.jpg"
              zvwShopLink={
                appContext.isPrintSubscriber
                  ? 'https://zvw-shop.de/app-angebote/samsung-tab-a-pl?utm_source=ext&utm_medium=link&utm_campaign=app-angebote'
                  : 'https://zvw-shop.de/app-angebote/samsung-tab-a-digi?utm_source=ext&utm_medium=link&utm_campaign=app-angebote'
              }
            />
            <Product
              title="Digital-Paket"
              subtitle="iPad (9.7 Zoll)"
              description="Erleben Sie unser meistverkauftes Tablet der neusten Generation mit besonders einfacher Bedienung. "
              oldPrice={appContext.isPrintSubscriber ? 35.4 : ''}
              currentPrice={appContext.isPrintSubscriber ? 16 : 35.4}
              additionalPayment={1}
              image="https://zvw-shop.de/media/image/a8/aa/40/A202-iPad.jpg"
              zvwShopLink={
                appContext.isPrintSubscriber
                  ? 'https://zvw-shop.de/app-angebote/ipad-print?utm_source=ext&utm_medium=link&utm_campaign=app-angebote'
                  : 'https://zvw-shop.de/app-angebote/ipad-digi?utm_source=ext&utm_medium=link&utm_campaign=app-angebote'
              }
            /> */}
          </ProductGrid>

          <ContactSection />

          <Modal
            isOpen={appContext.isPrintSubscriber === null}
            showClose={false}
          >
            <ModalWrapper>
              <ModalContent>
                {appContext.edition === 'zvw' ? (
                  <p>Haben Sie ihre Tageszeitung bereits abonniert?</p>
                ) : (
                  <p>
                    Haben Sie die{' '}
                    <strong> {newspapers[appContext.edition]}</strong> bereits
                    abonniert?
                  </p>
                )}

                <ButtonWrapper>
                  <Button onClick={() => appContext.setIsPrintSubscriber()}>
                    Ja, ich bin Abonnent
                  </Button>

                  <Button
                    className="negate"
                    onClick={() => appContext.setIsNoSubscriber()}
                  >
                    Nein
                  </Button>
                </ButtonWrapper>
              </ModalContent>
            </ModalWrapper>
          </Modal>

          <Modal isOpen={showTryModal} onClose={this.toggleTryModal}>
            <ModalWrapper>
              <ModalContent>
                <TryWrapper>
                  <strong>
                    Testen Sie unsere „Digitale Zeitung“ jetzt 14 Tage
                    kostenlos.
                  </strong>
                  <span>
                    Klicken Sie auf folgenden Link und geben Sie Ihren
                    Gutschein-Code ein.
                  </span>
                  {!isAndroid && !isIOS && (
                    <a
                      target="_blank"
                      href="http://zvw-epaper.s4p-iapps.com/coupon"
                    >
                      http://zvw-epaper.s4p-iapps.com/coupon
                    </a>
                  )}
                  {isAndroid && (
                    <a
                      target="_blank"
                      href="https://play.google.com/store/apps/details?id=de.msh.android.zvw&hl=de"
                    >
                      https://play.google.com/store/apps/details?id=de.msh.android.zvw&hl=de
                    </a>
                  )}
                  {isIOS && (
                    <a
                      target="_blank"
                      href="https://itunes.apple.com/de/app/zvw-epaper/id642946659?mt=8"
                    >
                      https://itunes.apple.com/de/app/zvw-epaper/id642946659?mt=8
                    </a>
                  )}
                  <Coupon>TESTAPP2</Coupon>
                </TryWrapper>
              </ModalContent>
            </ModalWrapper>
          </Modal>
        </Layout>
      </>
    )
  }
}

export default withAppContext(OffersPage)
