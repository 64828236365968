import React from 'react'
import styled from 'styled-components'
import { hsl } from 'polished'

import Arrow from '../images/arrow.svg'

const Wrapper = styled.a`
  padding: 13px 30px 9px 30px;
  background-color: ${hsl(198, 1, 0.45)};
  color: #fff;
  border: none;
  line-height: 1;
  transition: all 200ms linear;
  cursor: pointer;
  display: inline-block;

  &:hover {
    background-color: ${hsl(198, 1, 0.35)};
  }

  span {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;

    &:before {
      position: relative;
      top: -1px;
      display: block;
      width: 25px;
      height: 10px;
      background-image: url(${Arrow});
      background-repeat: no-repeat;
      content: '';
    }
  }
`

const ButtonLink = ({ children, ...rest }) => (
  <Wrapper {...rest}>
    <span>{children}</span>
  </Wrapper>
)

export default styled(ButtonLink)``
