import React from 'react'
import styled, { css } from 'styled-components'
import NumberFormat from 'react-number-format'
import { between } from 'polished'

import Button from './Button'
import ButtonLink from './ButtonLink'
import Container from './Container'

const GridWrapper = styled.div`
  padding: 40px 0;
  background-color: #f5f5f6;

  ${Container} {
    display: flex;
    flex-wrap: wrap;
  }
`

const ProductWrapper = styled.article`
  display: flex;
  justify-content: space-between;
  flex-basis: calc(50% - 20px);
  min-height: 300px;
  margin: 10px;
  padding: 20px;
  background-color: #fff;

  p {
    font-size: 17px;
  }

  footer {
    display: flex;
    justify-content: space-between;
  }

  @media (max-width: 945px) {
    flex-direction: column;

    ${({ highlightedOffer }) =>
      highlightedOffer &&
      css`
        flex-direction: row;

        ${LeftProductWrapper} {
          max-width: 45%;
          margin-right: 30px !important;
        }
      `}
  }

  @media (max-width: 575px) {
    flex-basis: 100%;

    ${({ highlightedOffer }) =>
      highlightedOffer &&
      css`
        flex-direction: column;

        ${LeftProductWrapper} {
          max-width: 100% !important;
          margin-right: 0 !important;
        }

        ${Description} {
          flex: unset !important;
        }
      `}
  }

  ${({ highlightedOffer }) =>
    highlightedOffer &&
    css`
      flex-basis: 100%;
      margin-bottom: 30px;
      border: 1px solid #00a1e6;
      box-shadow: 0px 6px 20px 0px #dad9d9;

      ${Title},
      ${CurrentPrice} {
        font-size: ${between('18px', '22px', '320px', '2560px')};
      }

      ${Title} {
        max-width: 100%;
      }

      ${Description},
      p {
        flex: 1;
        font-size: ${between('16px', '20px', '320px', '2560px')};
        max-width: 90%;
      }

      ${LeftProductWrapper} {
        max-width: 45%;
        margin-right: 160px;
      }

      ${AdditionalPayment} {
        sup {
          font-size: ${between('14px', '18px', '320px', '2560px')};
          top: ${between('-0.5em', '0em', '320px', '2560px')};
        }
      }
    `}
`

const LeftProductWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
`

const Title = styled.strong`
  line-height: 1.3;
  display: block;
  margin-bottom: 5px;
  max-width: 79%;

  @media (max-width: 645px) {
    max-width: 100%;
  }
`

const Description = styled.div`
  flex: 1;

  @media (max-width: 945px) {
    flex: unset;
  }
`

const Prices = styled.div``

const CurrentPrice = styled.span`
  font-weight: 700;
`

const OldPrice = styled.span`
  text-decoration: line-through;
`

const NormalPrice = styled.span`
  font-size: ${between('20px', '24px', '320px', '2560px')};

  span {
    text-decoration: line-through;
    color: red;
    font-size: ${between('24px', '28px', '320px', '2560px')};
  }
`

const ThankYouPrice = styled.span`
  font-size: ${between('20px', '24px', '320px', '2560px')};
  font-weight: 700;

  span {
    font-size: ${between('24px', '28px', '320px', '2560px')};
  }
`

const AdditionalPayment = styled.span`
  display: block;
  margin-top: 2px;
  line-height: 1;
`

const RightProductWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 0 220px;
  justify-content: flex-end;
  margin-left: 10px;

  @media (max-width: 945px) {
    flex: 1;
    flex-basis: 100%;
    margin: 0;
  }
`

const ImageWrapper = styled.div`
  flex: 1 0 auto;
  display: flex;
  justify-content: center;
  align-items: flex-end;
`

const Image = styled.img`
  display: block;
  max-width: 100%;
`

const Features = styled.div`
  margin-top: 20px;
  font-size: 18px;

  span {
    display: flex;
    align-items: center;
    margin-bottom: 15px;

    img {
      max-width: 23px;
      margin-right: 15px;
    }
  }
`

const ProductGrid = ({ children }) => (
  <GridWrapper>
    <Container>{children}</Container>
  </GridWrapper>
)

const Product = ({
  title,
  subtitle,
  description,
  features,
  oldPrice,
  currentPrice,
  additionalPayment,
  image,
  zvwShopLink,
  onButtonClick,
  ...rest
}) => (
  <ProductWrapper {...rest}>
    <LeftProductWrapper>
      <Title>
        {title}
        {subtitle ? ' -' : ''}
        {!rest.highlightedOffer && <br />}
        {subtitle}
      </Title>

      <Description>
        {description}
        {features && <Features>{features}</Features>}
      </Description>

      <Prices>
        {currentPrice || oldPrice ? (
          <>
            {!rest.thankYouOffer ? (
              <>
                <CurrentPrice>
                  {currentPrice > 0 && <>ab </>}
                  <NumberFormat
                    value={currentPrice || 0}
                    displayType={'text'}
                    thousandSeparator={true}
                    decimalScale={2}
                    thousandSeparator="."
                    decimalSeparator=","
                    fixedDecimalScale
                    suffix={' €'}
                  />
                  <sup>*</sup>
                </CurrentPrice>{' '}
                {oldPrice > 0 && (
                  <OldPrice>
                    <NumberFormat
                      value={oldPrice}
                      displayType={'text'}
                      thousandSeparator={true}
                      decimalScale={2}
                      thousandSeparator="."
                      decimalSeparator=","
                      fixedDecimalScale
                      suffix={' €'}
                    />
                    <sup>*</sup>
                  </OldPrice>
                )}
                {!rest.withoutAdditionalPayment && (
                  <AdditionalPayment>
                    <sup>
                      Zuzahlung:{' '}
                      <NumberFormat
                        value={additionalPayment || 0}
                        displayType={'text'}
                        thousandSeparator={true}
                        decimalScale={2}
                        thousandSeparator="."
                        decimalSeparator=","
                        fixedDecimalScale
                        suffix={' €'}
                      />
                    </sup>
                  </AdditionalPayment>
                )}
              </>
            ) : (
              <>
                <NormalPrice>
                  Normalpreis:{' '}
                  <NumberFormat
                    value={oldPrice}
                    displayType={'text'}
                    thousandSeparator={true}
                    decimalScale={2}
                    thousandSeparator="."
                    decimalSeparator=","
                    fixedDecimalScale
                    suffix={' €'}
                  />
                </NormalPrice>
                <br />
                <ThankYouPrice>
                  Ihr Dankeschön-Preis:{' '}
                  <NumberFormat
                    value={currentPrice}
                    displayType={'text'}
                    thousandSeparator={true}
                    decimalScale={2}
                    thousandSeparator="."
                    decimalSeparator=","
                    fixedDecimalScale
                    suffix={' €'}
                  />
                </ThankYouPrice>
              </>
            )}
          </>
        ) : (
          <strong>Kostenlos</strong>
        )}
      </Prices>
    </LeftProductWrapper>

    <RightProductWrapper>
      {image && (
        <ImageWrapper>
          <Image src={image} />
        </ImageWrapper>
      )}

      {!rest.withoutButton && (
        <>
          {!rest.highlightedOffer ? (
            <ButtonLink
              href={zvwShopLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              Jetzt bestellen
            </ButtonLink>
          ) : (
            <Button onClick={onButtonClick}>Jetzt kostenlos testen</Button>
          )}
        </>
      )}
    </RightProductWrapper>
  </ProductWrapper>
)

export { ProductGrid, Product }
