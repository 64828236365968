import React from 'react'
import styled from 'styled-components'

import Container from './Container'

const Wrapper = styled.div`
  padding: 50px 0;

  ${Container} {
    display: flex;

    @media (max-width: 945px) {
      flex-direction: column;
      flex: 1;
    }
  }
`

const Image = styled.img`
  position: absolute;
  width: 100%;
  left: -70px;
  bottom: -120px;

  @media (max-width: 945px) {
    display: none;
  }
`

const ImageWrapper = styled.div`
  position: relative;
  width: 1700px;
  margin-right: -40px;

  @media (max-width: 945px) {
    margin-right: 0px;
  }
`

const TextWrapper = styled.div``

const TextSection = ({ content, image }) => (
  <Wrapper>
    <Container>
      <ImageWrapper>
        <Image src={image} alt="" />
      </ImageWrapper>

      <TextWrapper>{content}</TextWrapper>
    </Container>
  </Wrapper>
)

export default TextSection
