import React, { PureComponent } from 'react'
import styled, { css } from 'styled-components'
import { rgba } from 'polished'
import CopyToClipboard from 'react-copy-to-clipboard'

const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 5px;

  button {
    margin-top: 15px;
    font-weight: 100;
    border: none;
    font-size: 18px !important;
    color: #009fe3;
    cursor: pointer;
    background-color: transparent;
    transition: all 200ms linear;

    &:hover {
      color: #08222d;
    }
  }
`

const Code = styled.div`
  flex-grow: 1;
  flex-shrink: 0;
  max-width: 220px;
  width: 100%;
  padding: 20px;
  margin-bottom: 0;
  border: 3px dashed #009fe3;
  background-color: #f3f3f3;
`

const Tooltip = styled.div`
  position: absolute;
  height: 39px;
  padding: 10px 10px 6px;
  font-size: 14px;
  right: 0;
  top: 20px;
  color: #fff;
  background-color: ${rgba('#000', 0.5)};
  border-radius: 5px;
  opacity: 0;
  visibility: hidden;
  transition: all 200ms linear;

  ${({ isVisible }) =>
    isVisible &&
    css`
      opacity: 1;
      visibility: visible;
    `}
`

class Coupon extends PureComponent {
  state = {
    showTooltip: false,
  }

  toggleToolip = () => {
    this.setState({ showTooltip: true }, () =>
      setTimeout(() => this.setState({ showTooltip: false }), 1500)
    )
  }

  render() {
    const { children } = this.props
    const { showTooltip } = this.state

    return (
      <Wrapper>
        <Code>{children}</Code>

        <CopyToClipboard text={children}>
          <button onClick={this.toggleToolip}>
            Code in die Zwischenablage kopieren
          </button>
        </CopyToClipboard>

        <Tooltip isVisible={showTooltip}>Code kopiert</Tooltip>
      </Wrapper>
    )
  }
}

export default Coupon
